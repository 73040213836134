import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import newsData from '../data/news.json';
import reviewsData from '../data/reviews.json';
import commentaryData from '../data/commentary.json';

const SourceLink = ({ source }) => {
  const [thumbnail, setThumbnail] = useState(source.thumbnail);

  useEffect(() => {
    const fetchYouTubeThumbnail = async () => {
      if (source.url.includes('youtube.com') || source.url.includes('youtu.be')) {
        let videoId;
        if (source.url.includes('youtube.com')) {
          videoId = source.url.split('v=')[1]?.split('&')[0];
        } else {
          videoId = source.url.split('youtu.be/')[1]?.split('?')[0];
        }
        
        if (videoId) {
          setThumbnail(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
        }
      }
    };

    if (!thumbnail) {
      fetchYouTubeThumbnail();
    }
  }, [source.url, thumbnail]);

  return (
    <div className="flex items-center gap-3 mb-2">
      {thumbnail && (
        <img 
          src={thumbnail} 
          alt={source.text} 
          className="w-16 h-16 object-cover rounded"
        />
      )}
      <a 
        href={source.url} 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-blue-600 dark:text-blue-400 hover:underline"
      >
        {source.text}
      </a>
    </div>
  );
};

const EpisodeCard = ({ episode }) => {
  const { id, title, description, date, thumbnail, sources } = episode;

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mb-8">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          <img src={thumbnail} alt={title} className="w-full h-full object-cover" />
        </div>
        <div className="w-full md:w-1/2 p-6">
          <Link 
            to={`/episode/${id}`}
            className="text-xl font-semibold text-red-700 dark:text-red-500 hover:text-red-600 dark:hover:text-red-400 mb-2 block"
          >
            {title}
          </Link>
          <p className="text-sm font-bold text-gray-600 dark:text-gray-400 mb-2">Air Date: {date}</p>
          <p className="text-gray-600 dark:text-gray-300 mb-4">{description}</p>
          
          <Link 
            to={`/episode/${id}`}
            className="inline-flex items-center justify-center px-6 py-3 bg-red-700 hover:bg-red-600 text-white rounded-lg shadow-md transition-colors duration-200"
          >
            <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path d="M6.3 2.841A1.5 1.5 0 004 4.11v11.78a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z"/>
            </svg>
            Listen Now
          </Link>
          
          {sources && sources.length > 0 && (
            <div className="mt-4">
              <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">Sources & References</h4>
              <div className="space-y-2">
                {sources.map((source, index) => (
                  <SourceLink key={index} source={source} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EpisodeSection = ({ title, episodes, category }) => (
  <section className="mb-12">
    <h2 className="text-2xl font-bold mb-4 text-red-700 dark:text-red-500">{title}</h2>
    {episodes.slice(0, 3).map(episode => (
      <EpisodeCard key={episode.id} episode={episode} />
    ))}
    {episodes.length > 3 && (
      <Link to={`/episodes/${category.toLowerCase()}`} className="text-blue-600 dark:text-blue-400 hover:underline">
        View all {category} episodes
      </Link>
    )}
  </section>
);

const EpisodesPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-[1080px] mx-auto">
          <Navigation />
        </div>
      </header>

      <main className="container mx-auto px-4 py-8 max-w-[1080px]">
        <h1 className="text-4xl font-bold mb-8 text-gray-800 dark:text-gray-200">Latest Episodes</h1>

        {newsData.episodes && newsData.episodes.length > 0 && (
          <EpisodeSection title="News" episodes={newsData.episodes} category="News" />
        )}
        {reviewsData.episodes && reviewsData.episodes.length > 0 && (
          <EpisodeSection title="Reviews" episodes={reviewsData.episodes} category="Reviews" />
        )}
        {commentaryData.episodes && commentaryData.episodes.length > 0 && (
          <EpisodeSection title="Commentary" episodes={commentaryData.episodes} category="Commentary" />
        )}
      </main>

      <Footer />
    </div>
  );
};

export default EpisodesPage;
