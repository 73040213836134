import React from 'react';
import podcastsData from '../data/podcasts.json';

const Footer = () => {
  const { socialMedia, podcastInfo } = podcastsData;

  const SocialIcon = ({ platform, url }) => {
    const iconProps = "hover:text-gray-300";
    const title = `Follow us on ${platform.charAt(0).toUpperCase() + platform.slice(1)}`;
    
    const IconComponent = () => {
      switch (platform) {
        case 'facebook':
          return <i className={`fab fa-facebook ${iconProps}`} />;
        case 'instagram':
          return <i className={`fab fa-instagram ${iconProps}`} />;
        case 'twitter':
          return <i className={`fab fa-twitter ${iconProps}`} />;
        case 'youtube':
          return <i className={`fab fa-youtube ${iconProps}`} />;
        case 'spotify':
          return <i className={`fab fa-spotify ${iconProps}`} />;
        case 'applePodcasts':
          return <i className={`fab fa-apple ${iconProps}`} />;
        case 'amazonMusic':
          return <i className={`fab fa-amazon ${iconProps}`} />;
        default:
          return null;
      }
    };

    return (
      <a href={url} aria-label={title} title={title}>
        <IconComponent />
      </a>
    );
  };

  return (
    <footer className="bg-[rgb(160,70,71)] dark:bg-gray-800 shadow text-white py-8">
      <div className="max-w-[1080px] mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div>
            <h3 className="text-2xl font-bold mb-2">Contact Us</h3>
            <p>{podcastInfo.email}</p>
          </div>
          <div className="flex space-x-4">
            {Object.entries(socialMedia).map(([platform, url]) => (
              <SocialIcon key={platform} platform={platform} url={url} />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
