import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import newsData from '../data/news.json';
import reviewsData from '../data/reviews.json';
import commentaryData from '../data/commentary.json';

const EpisodeCard = ({ title, description, date, thumbnail, audioFile }) => (
  <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mb-8">
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/2">
        <img src={thumbnail} alt={title} className="w-full h-full object-cover" />
      </div>
      <div className="w-full md:w-1/2 p-6">
        <h3 className="text-xl font-semibold text-red-700 dark:text-red-500 mb-2">{title}</h3>
        <p className="text-sm font-bold text-gray-600 dark:text-gray-400 mb-2">Air Date: {date}</p>
        <p className="text-gray-600 dark:text-gray-300 mb-2">{description}</p>
      </div>
    </div>
    <div className="p-6">
      <audio controls className="w-full">
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>
);

const EpisodeCategory = () => {
  const { category } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const episodesPerPage = 10;

  const getCategoryData = (category) => {
    switch (category.toLowerCase()) {
      case 'news':
        return newsData.episodes;
      case 'reviews':
        return reviewsData.episodes;
      case 'commentary':
        return commentaryData.episodes;
      default:
        return [];
    }
  };

  const categoryEpisodes = getCategoryData(category);

  const indexOfLastEpisode = currentPage * episodesPerPage;
  const indexOfFirstEpisode = indexOfLastEpisode - episodesPerPage;
  const currentEpisodes = categoryEpisodes.slice(indexOfFirstEpisode, indexOfLastEpisode);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const categoryTitle = category.charAt(0).toUpperCase() + category.slice(1);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-[1080px] mx-auto">
          <Navigation />
        </div>
      </header>

      <main className="container mx-auto px-4 py-8 max-w-[1080px]">
        <h1 className="text-4xl font-bold mb-8 text-gray-800 dark:text-gray-200">{categoryTitle} Episodes</h1>

        {currentEpisodes.map(episode => (
          <EpisodeCard key={episode.id} {...episode} />
        ))}

        <div className="flex justify-center mt-8">
          {Array.from({ length: Math.ceil(categoryEpisodes.length / episodesPerPage) }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i + 1
                  ? 'bg-red-700 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default EpisodeCategory;