import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import newsData from '../data/news.json';
import reviewsData from '../data/reviews.json';
import commentaryData from '../data/commentary.json';

const SourceLink = ({ source }) => {
  const [thumbnail, setThumbnail] = useState(source.thumbnail);

  useEffect(() => {
    const fetchYouTubeThumbnail = async () => {
      if (source.url.includes('youtube.com') || source.url.includes('youtu.be')) {
        let videoId;
        if (source.url.includes('youtube.com')) {
          videoId = source.url.split('v=')[1]?.split('&')[0];
        } else {
          videoId = source.url.split('youtu.be/')[1]?.split('?')[0];
        }
        
        if (videoId) {
          setThumbnail(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
        }
      }
    };

    if (!thumbnail) {
      fetchYouTubeThumbnail();
    }
  }, [source.url, thumbnail]);

  return (
    <div className="flex items-center gap-3 mb-2">
      {thumbnail && (
        <img 
          src={thumbnail} 
          alt={source.text} 
          className="w-16 h-16 object-cover rounded"
        />
      )}
      <a 
        href={source.url} 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-blue-600 dark:text-blue-400 hover:underline"
      >
        {source.text}
      </a>
    </div>
  );
};

const EpisodePage = () => {
  const { id } = useParams();

  // Find episode across all categories
  const allEpisodes = [
    ...(newsData.episodes || []),
    ...(reviewsData.episodes || []),
    ...(commentaryData.episodes || [])
  ];
  const episode = allEpisodes.find(ep => ep.id === parseInt(id, 10));

  if (!episode) {
    return (
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
        <header className="bg-white dark:bg-gray-800 shadow">
          <div className="max-w-[1080px] mx-auto">
            <Navigation />
          </div>
        </header>
        <main className="container mx-auto px-4 py-8 max-w-[1080px]">
          <h1 className="text-4xl font-bold mb-8">Episode Not Found</h1>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-[1080px] mx-auto">
          <Navigation />
        </div>
      </header>

      <main className="container mx-auto px-4 py-8 max-w-[1080px]">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
          <div className="aspect-w-16 aspect-h-9">
            <img 
              src={episode.thumbnail} 
              alt={episode.title} 
              className="w-full h-full object-cover"
            />
          </div>
          
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-2 text-red-700 dark:text-red-500">
              {episode.title}
            </h1>
            <p className="text-sm font-bold text-gray-600 dark:text-gray-400 mb-4">
              Air Date: {episode.date}
            </p>
            <div className="flex items-center text-sm font-bold text-gray-600 dark:text-gray-400 mb-4">
              <span>Listen to episode on:</span>
              <a href={episode.spotifyEpisode} className="ml-2">
                <i className="fab fa-spotify fa-2x text-green-500 hover:text-green-400"></i>
              </a>
              <a href={episode.appleEpisode} className="ml-2">
                <i className="fab fa-apple fa-2x text-red-800 dark:text-red-800 hover:text-red-500 dark:hover:text-red-500"></i>
              </a>
            </div>
            <p className="text-gray-600 dark:text-gray-300 mb-6"> 
              {episode.description}
            </p>

            <div className="mb-6">
              <audio 
                controls 
                className="w-full"
                preload="auto"
              >
                <source src={episode.audioFile} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>

            {episode.sources && episode.sources.length > 0 && (
              <div className="mt-8">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                  Sources & References
                </h2>
                <div className="space-y-4">
                  {episode.sources.map((source, index) => (
                    <SourceLink key={index} source={source} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default EpisodePage;
