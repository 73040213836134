import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import newsData from '../data/news.json';
import reviewsData from '../data/reviews.json';
import commentaryData from '../data/commentary.json';
import podcastsData from '../data/podcasts.json';

const PodcastIcon = ({ platform, url }) => {
  const iconProps = "fill-current text-red-700 hover:text-red-600";
  const title = `Listen on ${platform.charAt(0).toUpperCase() + platform.slice(1)}`;
  
  const IconComponent = () => {
    switch (platform) {
      case 'spotify':
        return <i className={`fab fa-spotify ${iconProps}`} />;
      case 'appleMusic':
        return <i className={`fab fa-apple ${iconProps}`} />;
      default:
        return null;
    }
  };

  return (
    <a href={url} aria-label={title} title={title} className="mr-2">
      <IconComponent />
    </a>
  );
};

const SourceLink = ({ source }) => {
  const [thumbnail, setThumbnail] = useState(source.thumbnail);

  useEffect(() => {
    const fetchYouTubeThumbnail = async () => {
      if (source.url.includes('youtube.com') || source.url.includes('youtu.be')) {
        let videoId;
        if (source.url.includes('youtube.com')) {
          videoId = source.url.split('v=')[1]?.split('&')[0];
        } else {
          videoId = source.url.split('youtu.be/')[1]?.split('?')[0];
        }
        
        if (videoId) {
          setThumbnail(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
        }
      }
    };

    if (!thumbnail) {
      fetchYouTubeThumbnail();
    }
  }, [source.url, thumbnail]);

  return (
    <div className="flex items-center gap-3 mb-2">
      {thumbnail && (
        <img 
          src={thumbnail} 
          alt={source.text} 
          className="w-16 h-16 object-cover rounded"
        />
      )}
      <a 
        href={source.url} 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-blue-600 dark:text-blue-400 hover:underline"
      >
        {source.text}
      </a>
    </div>
  );
};

const HomePage = () => {
  const getLatestEpisode = () => {
    const allEpisodes = [
      ...(newsData.episodes || []),
      ...(reviewsData.episodes || []),
      ...(commentaryData.episodes || [])
    ];

    return allEpisodes.sort((a, b) => new Date(b.date) - new Date(a.date))[0] || null;
  };

  const latestEpisode = getLatestEpisode();
  const { podcastInfo, socialMedia } = podcastsData;

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <header className="bg-white dark:bg-gray-800 shadow">
        <div className="max-w-[1080px] mx-auto">
          <Navigation />
        </div>
      </header>

      <main className="container mx-auto px-4 py-8 max-w-[1080px]">
        <section className="mb-12" id="intro-section">
          <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
            <img src="/assets/images/Tai-Aimee.webp" alt={`${podcastInfo.hosts.join(' and ')}, hosts of ${podcastInfo.name}`} className="w-full aspect-[24/10] object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-2 text-red-700 dark:text-red-500">{podcastInfo.name}</h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">{podcastInfo.description}</p>
              <div className="flex items-center">
                <span className="mr-2 text-gray-700 dark:text-gray-300">Listen on:</span>
                {Object.entries(socialMedia)
                  .filter(([platform]) => ['spotify', 'appleMusic'].includes(platform))
                  .map(([platform, url]) => (
                    <PodcastIcon key={platform} platform={platform} url={url} />
                  ))
                }
              </div>
            </div>
          </div>
        </section>

        {latestEpisode && (
          <section className="mb-12" id="latest-episode-section">
            <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Latest Episode</h3>
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2">
                  <img src={latestEpisode.thumbnail} alt={latestEpisode.title} className="w-full h-full object-cover" />
                </div>
                <div className="w-full md:w-1/2 p-6">
                  <Link 
                    to={`/episode/${latestEpisode.id}`}
                    className="text-xl font-semibold mb-2 text-red-700 dark:text-red-500 hover:text-red-600 dark:hover:text-red-400 block"
                  >
                    {latestEpisode.title}
                  </Link>
                  <p className="text-sm font-bold text-gray-600 dark:text-gray-400 mb-2">Air Date: {latestEpisode.date}</p>
                  <p className="text-gray-600 dark:text-gray-400 mb-4">{latestEpisode.description}</p>
                  
                  <Link 
                    to={`/episode/${latestEpisode.id}`}
                    className="inline-flex items-center justify-center px-6 py-3 bg-red-700 hover:bg-red-600 text-white rounded-lg shadow-md transition-colors duration-200"
                  >
                    <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.3 2.841A1.5 1.5 0 004 4.11v11.78a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z"/>
                    </svg>
                    Listen Now
                  </Link>
                  
                  {latestEpisode.sources && latestEpisode.sources.length > 0 && (
                    <div className="mt-4">
                      <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2">Sources & References</h4>
                      <div className="space-y-2">
                        {latestEpisode.sources.map((source, index) => (
                          <SourceLink key={index} source={source} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="mb-12" id="reviews-section">
          <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Reviews</h3>
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <p className="text-gray-600 dark:text-gray-400 italic">"News from the Pew offers insightful commentary on the evolving landscape of American Christianity. A must-listen!"</p>
            <p className="text-gray-800 dark:text-gray-200 font-semibold mt-2">- Christian Today Magazine</p>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default HomePage;
