import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import ThemeToggle from './ThemeToggle';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const linkClass = (path) => {
    return `${isActive(path) 
      ? 'text-[rgb(160,70,71)] dark:text-red-500' 
      : 'text-gray-600 dark:text-gray-300 hover:text-[rgb(160,70,71)] dark:hover:text-red-500'
    }`;
  };

  return (
    <nav className="relative">
      <div className="max-w-[1080px] mx-auto px-4 py-2 flex justify-between items-center">
        <div className="w-48">
          <Link to="/">
            <img src="/assets/images/logo.png" alt="News from the Pew" className="w-full h-auto" />
          </Link>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          <ul className="flex space-x-4">
            <li><Link to="/" className={linkClass('/')}>Home</Link></li>
            <li><Link to="/episodes" className={linkClass('/episodes')}>Episodes</Link></li>
            <li><Link to="/contact" className={linkClass('/contact')}>Contact</Link></li>
          </ul>
          <ThemeToggle />
        </div>
        <button className="md:hidden text-gray-600 dark:text-gray-300" onClick={toggleMenu}>
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-full left-0 right-0 bg-white dark:bg-gray-800 shadow-md md:hidden">
          <div className="max-w-[1080px] mx-auto px-4">
            <ul className="flex flex-col py-4">
              <li className="mb-2"><Link to="/" className={linkClass('/')} onClick={toggleMenu}>Home</Link></li>
              <li className="mb-2"><Link to="/episodes" className={linkClass('/episodes')} onClick={toggleMenu}>Episodes</Link></li>
              <li className="mb-2"><Link to="/contact" className={linkClass('/contact')} onClick={toggleMenu}>Contact</Link></li>
            </ul>
            <div className="py-4">
              <ThemeToggle />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;